
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import _ from "lodash";
import BatchChart from "@/components/dashboard/batch-chart.vue";
import BatchIngredient from "@/components/dashboard/batch-ingredient.vue";
import Timeline from "@/components//batch/timeline.vue";
import Flags from "./batch/flags.vue";
import { updateBatch } from "./devices/helpers";
const keys = ["COMMENT", "INTERVENTION", "author"];
@Component({
  components: { BatchChart, BatchIngredient, Timeline, Flags },
  computed: {
    batch() {
      return (
        this.$store.state?.running_batches?.find(
          (x: any) => x.id == this.$route.params.batch_id
        ) ??
        this.$store.state.all_batches?.find(
          (x: any) => x.id == this.$route.params.batch_id
        )
      );
    },
    measurements() {
      return (
        (this as any).batch?.reference_measurements?.map(Object.entries) ?? []
      );
    },
  },
  deactivated() {
    clearInterval(this.$store.state.loader);
  },
  async mounted() {
    this.$store.dispatch("loadBatches");
    document.title = (this as any).batch?.name + " - lab.zymoscope.com";
  },
  data: () => ({
    measurements_error: null,
    offset_hours: 0,
    saving_flag: null,
    input: {
      COMMENT: null,
      INTERVENTION: null,
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:ss"),
    },
    moment,
    show_debug: false,
    ld: _,
  }),
  methods: {
    async updateFlags(flag, value) {
      try {
        console.warn("updateFlags", { flag, value });
        this.$data.saving_flag = flag;

        await updateBatch(
          (this as any).batch.id,
          { ["flags." + flag]: value },
          this.$store.getters.tb_api_headers
        );
      } catch (error) {
        alert("Error occured when updating flag. Details: " + error);
      }
      this.$data.saving_flag = null;
    },
  },
})
export default class ApEasyDens extends Vue {}
