
import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/login.vue"; // @ is an alias to /src
import BatchDetails from "@/components/batch-details.vue";

window.document.title = "Industrial Measurements - fc.zymoscope.com";
@Component({
  components: {
    Login,
    BatchDetails,
  },
  computed: {},
  data: () => ({}),
  methods: {},
})
export default class Home extends Vue {}
