
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
  props: ["batch"],
  computed: {
  },
  methods: {},
})
export default class BatchFlags extends Vue {}
